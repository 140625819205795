<template>
<div>
    <div class="wrap">
        <div class="con">
            <div class="con_table">
                <div class="con_top mb-10">
                    <h2><span>■</span> 센터 상세정보</h2>
                </div>

                <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                    <tbody>
                        <tr>
                            <th class="w-120px">센터명</th>
                            <td class="left pl-20" colspan="3">
                                {{center.name}}
                            </td>
                        </tr>
                        <tr>
                            <th>대표번호</th>
                            <td class="left pl-20">
                                {{center.mainNumber}}
                            </td>
                            <th>센터장</th>
                            <td class="left pl-20">
                                {{center.centerCap}}
                            </td>
                        </tr>
                        <tr>
                            <th>휴대폰</th>
                            <td class="left pl-20">
                                {{center.phone}}
                            </td>
                            <th>상담실</th>
                            <td class="left pl-20">
                                {{center.consRoom}}개
                            </td>
                        </tr>
                        <tr>
                            <th>놀이치료실</th>
                            <td class="left pl-20">
                                {{center.playRoom}}개
                            </td>
                            <th>상담서비스</th>
                            <td class="left pl-20">
                                {{center.consService}}
                            </td>
                        </tr>
                        <tr>
                            <th>정산유형</th>
                            <td class="left pl-20">
                                {{formatPurType(center.purType)}}
                            </td>
                            <th>상담료</th>
                            <td class="left pl-20">
                                {{center.consPrice}}
                            </td>
                        </tr>
                        <tr>
                            <th>홈페이지</th>
                            <td class="left pl-20">
                                {{center.homepage}}
                            </td>
                            <th>진행현황</th>
                            <td class="left pl-20">
                                {{center.contractEndYn}}
                            </td>
                        </tr>
                        <tr>
                            <th>통장사본</th>
                            <td class="left pl-20 w-400px">
                                <!-- <img v-if="centerBankUrl !== ''" class="w-300px" :src="centerBankUrl"> -->
                                <a v-if="centerBankUrl !== ''" :href="centerBankUrl" target="blank" class="w-300px pointer txt-black">{{centerBankName}}</a>
                                <span v-else class="w-500px">사진 없음</span>
                            </td>
                            <th>사업자등록증</th>
                            <td class="left pl-20">
                                <!-- <img v-if="centerRegUrl !== ''" class="w-300px" :src="centerRegUrl"> -->
                                <a v-if="centerRegUrl !== ''" :href="centerRegUrl" target="blank" class="w-300px pointer txt-black">{{centerRegName}}</a>
                                <span v-else class="w-300px">사진 없음</span>
                            </td>
                        </tr>
                        <tr>
                            <th>센터사진</th>
                            <td class="left pl-20 w-400px">
                                <a v-if="centerPhotoUrl1 !== ''" :href="centerPhotoUrl1" target="blank" class="w-300px pointer txt-black">{{centerPhotoName1}}<br></a>
                                <a v-if="centerPhotoUrl2 !== ''" :href="centerPhotoUrl2" target="blank" class="w-300px pointer txt-black">{{centerPhotoName2}}<br></a>
                                <a v-if="centerPhotoUrl3 !== ''" :href="centerPhotoUrl3" target="blank" class="w-300px pointer txt-black">{{centerPhotoName3}}</a>
                                <span v-if="centerPhotoUrl1 === '' && centerPhotoUrl2 === '' && centerPhotoUrl3 === ''" class="w-300px">사진 없음</span>
                            </td>
                            <th></th>
                            <td class="left pl-20 w-400px txt-center">
                            </td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td class="left pl-20 txt-center" colspan="3">
                                <div id="dmap">
                                    <div class="txt-left">{{center.address}}</div>
                                    <div id="map" style="width:100%; height:400px;"></div>
                                    <div id="clickLatlng" style="display:none"></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="con">
            <div class="con_top mb-10">
                <h2><span>■</span> 소속 상담사</h2>
            </div>

            <div class="con_table">
                <table class="default_table mt-20">
                    <tbody v-if="loading">
                        <tr>
                            <th>No</th>
                            <th>상담사명</th>
                            <th>성별</th>
                            <th>센터</th>
                            <th>전문분야</th>
                            <th>관리</th>
                        </tr>
                        <tr>
                            <td colspan="6">검색중입니다.</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <th>No</th>
                            <th>상담사명</th>
                            <th>성별</th>
                            <th>센터</th>
                            <th>전문분야</th>
                        </tr>
                        <tr v-for="(item, index) of userList" :key="index">
                            <td>{{item.rownum}}</td>
                            <td class="underline pointer blue" @click="detailCard(item.idx)">{{item.name}}</td>
                            <td>{{item.gender || '-'}}</td>
                            <td>{{item.centerName || '-'}}</td>
                            <td>{{item.proName || '-'}}</td>
                        </tr>
                        <tr v-if="userList.length === 0">
                            <td colspan="6">검색 결과가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="con_table">
                <div class="page">
                    <ul>
                        <li>
                            <a class="pointer" @click="prevPaging()"> &lt; </a>
                        </li>
                        <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                            <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                <a :class="{'bold': index === pageNum}">{{index}}</a>
                            </span>
                        </li>
                        <li>
                            <a class="pointer" @click="nextPaging()"> &gt; </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        

        

        <div class="btns3 mgB80">
            <a class="btn_sms mr-10 pointer" @click="prev()">목록</a>
            <a class="btn_cancle pointer" @click="modify(idx)">수정</a>
        </div>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({

        center: {}, // 센터정보  

        idx: -1, // 센터 고유 idx
        location: '', // 다음 맵 연동 주소

        centerBankUrl: '', // 통장사본 이미지 url
        centerBankName: '', 
        centerPhotoUrl1: '',  // 센터사진 이미지 url
        centerPhotoName1: '',  // 센터사진 이미지 url
        centerPhotoUrl2: '',  // 센터사진 이미지 url
        centerPhotoName2: '',  // 센터사진 이미지 url
        centerPhotoUrl3: '',  // 센터사진 이미지 url
        centerPhotoName3: '',  // 센터사진 이미지 url
        centerRegUrl: '', // 사업자등록증 이미지 url
        centerRegName: '', // 사업자등록증 이미지 url

        pageNum: 1,
        pageSize: 5,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {
        this.fetchInit()
    },

    methods: {
        // 마운트시 시작 api 연동
        async fetchInit() {
            var oParams = GetUrlParams()
            this.idx = oParams.idx;
            await this.getUserList(); //상담사 리스트
            await this.getCenter(this.idx)
            await this.getDaumMap() // 페이지 호출시 다음 맵 호출
            
           
        },

        // 정산유형 타입 
        formatPurType(type) {
            switch (type) {
                case 'T':
                    return '과세'
                case 'F':
                    return '면세'
                case 'D':
                    return '소득공제 3.3%'
            }
        },

        // 센터 정보 api 호출
        async getCenter(idx) {
            var params = {
                idx: idx
            }

            await this.axios.get(`/api/v1/center/${idx}`, {
                    params: params
                })
                .then(res => {
                    
                    if (res.data.err === 0) {
                        this.center = res.data.center
                        this.location = res.data.center.address

                        // 통장사본 있는 경우 값 할당
                        if (res.data.center.centerBankUrl && res.data.center.centerBankUrl.includes('object.ncloudstorage.com')) {
                            this.centerBankUrl = res.data.center.centerBankUrl
                            this.centerBankName = res.data.center.centerBankName
                        }
                        // 사업자등록증 있는 경우 값 할당
                        if (res.data.center.centerRegUrl && res.data.center.centerRegUrl.includes('object.ncloudstorage.com')) {
                            this.centerRegUrl = res.data.center.centerRegUrl
                            this.centerRegName = res.data.center.centerRegName
                        }
                        // 센터사진 있는 경우 값 할당
                        if (res.data.center.centerPhotoUrl1 && res.data.center.centerPhotoUrl1.includes('object.ncloudstorage.com')) {
                            this.centerPhotoUrl1 = res.data.center.centerPhotoUrl1
                            this.centerPhotoName1 = res.data.center.centerPhotoName1
                        }
                        // 센터사진 있는 경우 값 할당
                        if (res.data.center.centerPhotoUrl2 && res.data.center.centerPhotoUrl2.includes('object.ncloudstorage.com')) {
                            this.centerPhotoUrl2 = res.data.center.centerPhotoUrl2
                            this.centerPhotoName2 = res.data.center.centerPhotoName2
                        }
                        // 센터사진 있는 경우 값 할당
                        if (res.data.center.centerPhotoUrl3 && res.data.center.centerPhotoUrl3.includes('object.ncloudstorage.com')) {
                            this.centerPhotoUrl3 = res.data.center.centerPhotoUrl3
                            this.centerPhotoName3 = res.data.center.centerPhotoName3
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 다음 맵 호출 및 설정
        async getDaumMap() {
            var container = document.getElementById('map');
            var mapOptions = {
                center: new daum.maps.LatLng(33.450701, 126.570667),
                level: 4 //지도의 레벨(확대, 축소 정도)
            };
            var map = new daum.maps.Map(container, mapOptions);

            var markerPosition = new daum.maps.LatLng(33.450701, 126.570667);

            // 마커를 생성합니다
            var marker = new daum.maps.Marker({
                position: markerPosition
            });

            // 마커가 지도 위에 표시되도록 설정합니다
            marker.setMap(map);

            // 주소-좌표 변환 객체를 생성합니다 
            var geocoder = new daum.maps.services.Geocoder(); // 주소로 좌표를 검색합니다 
            var me = this
            geocoder.addressSearch(this.location, function (result, status) {
                // 정상적으로 검색이 완료됐으면 
                if (status === daum.maps.services.Status.OK) {
                    var coords = new daum.maps.LatLng(result[0].y, result[0].x);
                    var message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
                    message += result[0].x + ')';
                    var resultDiv = document.getElementById('clickLatlng');
                    resultDiv.innerHTML = message;
                    // 결과값으로 받은 위치를 마커로 표시합니다 
                    var marker = new daum.maps.Marker({
                        map: map,
                        position: coords
                    });
                    // 인포윈도우로 장소에 대한 설명을 표시합니다 
                    var infowindow = new daum.maps.InfoWindow({
                        content: `<div style="width:150px;text-align:center;padding:6px 0;">${me.location}</div>`
                    });
                    infowindow.open(map, marker);
                    // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다 
                    map.setCenter(coords);
                }
            });
        },

        // 이전 페이지로 돌아가기
        prev() {
            history.back()
        },

        // 센터수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/center_manage_modify?idx=${idx}`)
        },

        /**
         * @description : 상담사 리스트
         */
        getUserList(){
            const params ={
                idxCrmCenter: this.idx,
                permission: 'Y',
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };

            this.loading = true;
            this.axios.get('/api/v2/user/list',{
                params: params
            })
            .then((res) => {
                this.userList = res.data.userList
                if (res.data.userList) {
                    this.listLength = res.data.userListCount
                }
                this.maxPagingCount = Math.ceil(this.listLength / this.pageSize);
                this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
                this.pagePart = Math.ceil(this.pageNum / 10);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(()=>{
                this.loading = false;
            });
        },

        /**
         * @description 현재 페이지의 페이지 범위 계산
         */
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        /**
         * @description 이전 페이징
         */
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getUserList()
            }
        },

        /**
         * @description : 현재 페이징
         */
        paging(index) {
            this.pageNum = index
            this.getUserList()
        },

        /**
         * @description : 다음 페이징
         */
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getUserList()
            }
        },

        /**
         * @description : 상담사 카드
         */
        detailCard(idx) {
            this.$router.push(`/origin/counselor_manage_card?idx=${idx}`)
        },
    }
}
</script>
